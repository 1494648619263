<template >
  <b-overlay
    :show="isLoading"
    variant="light"
    :opacity="0.75"
    blur="2px"
    centered
    style="min-height: 10rem"
  >
    <div>
      <validation-observer ref="addSallingValidation">
        <b-row style="width: 100%">
          <b-col cols="12">
            <div class="mb-2">
              <div
                class="d-flex align-items-center justify-content-between border-bottom"
              >
                <span
                  class="border-bottom-primary h3"
                  style="border-bottom-width: 4px; padding-bottom: 4px"
                  >Tạo phiếu xuất kho</span
                >
              </div>
            </div>
          </b-col>
          <b-col cols="10" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="Chọn Kho"
              rules="required"
            >
              <h5 class for>
                Chọn Kho
                <span class="text-danger">*</span>
              </h5>
              <SelectInventory
                v-model="inventoryInfo"
                @onChangeInventory="handleChangeInventory"
              />

              <small class="text-danger">{{
                errors[0] ? "Vui lòng chọn Kho" : ""
              }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="2" class="mb-2">
            <b-button
              block
              :disabled="isLoading"
              class="h-full"
              variant="primary"
              @click="createStockOut()"
              >Tạo phiếu xuất</b-button
            >
          </b-col>
          <b-col v-if="customerInfor" cols="3" class="border-right">
            <h5 class="mb-1" for>Tên khách hàng: {{ customerInfor.name }}</h5>
            <h5 class="mb-1" for>Số điện thoại: {{ customerInfor.phone }}</h5>
            <h5 class="mb-1" for>Địa chỉ: {{ getAddress(customerInfor) }}</h5>
          </b-col>
          <b-col cols="9" :key="componentKey">
            <b-row
              class="border-bottom p-0"
              v-for="(item, orderItemIndex) in orderProductItems"
              :key="item.id"
            >
              <b-col cols="8" class="">
                <div>
                  <h5 class="mb-1" for>
                    Tên sản phẩm: {{ item.data.product_name }}
                  </h5>
                  <h5 class="mb-1" for>
                    Đơn vị: {{ item.data.product_variant_unit_name }}
                  </h5>
                  <h5 class="mb-1" for>Số lượng: {{ item.data.qty }}</h5>
                </div>
              </b-col>
              <b-col cols="4" class="flex items-center">
                <b-list-group style="overflow-y: auto">
                  <div
                    v-for="(lot, indexLot) in item.lot"
                    :key="lot.id"
                    class="d-flex flex-row mb-1 items-center cursor-pointer"
                  >
                    <b-form-checkbox
                      :checked="lot.active"
                      @change="onClickLot(orderItemIndex, indexLot, lot, item)"
                    ></b-form-checkbox>
                    <b-list-group-item
                      @click="onClickLot(orderItemIndex, indexLot, lot, item)"
                      :active="lot.active"
                      class="flex-column align-items-start w-100 rounded-lg"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h6
                          class="flex flex-col gap-1"
                          :class="lot.active ? 'text-white' : ''"
                        >
                          <span>#{{ lot.product_lot.name }}</span>
                          <span
                            >Ngày sản xuất:
                            {{
                              appUtils.formatDate(
                                lot.product_lot.manufacture_date * 1000
                              )
                            }}
                          </span>
                          <span
                            >Ngày hết hạn:
                            {{
                              appUtils.formatDate(
                                lot.product_lot.expiration_date * 1000
                              )
                            }}</span
                          >
                        </h6>
                      </div>
                      <b-card-text>Tồn kho: {{ lot.balance }}</b-card-text>
                    </b-list-group-item>
                  </div>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- <b-col cols="12">
                <div class="mb-3">
                    <div class="h3 d-flex align-items-center justify-content-between border-bottom">
                    <span
                        class="border-bottom-primary"
                        style="border-bottom-width: 4px; padding-bottom: 4px;"
                    >Tạo phiếu xuất kho</span>
                    </div>
                </div>
          </b-col>-->
        </b-row>
      </validation-observer>
    </div>
  </b-overlay>
</template>
  
  <script>
import { BRow, BCol, BFormCheckboxGroup, BFormCheckbox } from "bootstrap-vue";
import appUtils from "@/utils/appUtils";
import { ORDERS_STATUS, PRODUCT_TYPES } from "@/utils/constant";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SelectInventory from "@/components/SelectInventory/index.vue";

export default {
  name: "ModalExport",
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectInventory,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      appUtils,
      ORDERS_STATUS,
      ordererForm: {},
      receiverForm: {},
      show: false,
      isEdit: false,
      showModalExport: false,
      inventoryInfo: {},
      componentKey: 0,
      newRecordData: {
        order_items: [],
      },
      selectedLot: [],
      isLoading: false,
      orderProductItems: [],
    };
  },
  watch: {
    newRecordData: {
      handler(data) {
        if (data) {
          this.orderProductItems =
            data?.order_items.filter(
              (item) => item.data?.type === PRODUCT_TYPES.PRODUCT
            ) || [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    customerInfor() {
      const customer = this.newRecordData.order_contact.find(
        (x) => x.type === 2
      )
        ? this.newRecordData.order_contact.find((x) => x.type === 2)
        : this.newRecordData.order_contact[0];

      return customer;
    },
  },
  created() {
    this.newRecordData = { ...this.recordData };
  },
  methods: {
    async createStockOut() {
      const isSelectAllLot = this.orderProductItems?.every((item) =>
        item?.lot?.some((child) => child?.active)
      );

      if (!isSelectAllLot) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Vui lòng chọn lô cho sản phẩm"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      const params = {
        stock_out_type: 1,
        inventory_id: this.inventoryInfo.id,
        order_id: this.newRecordData.id,
        customer_id: this.customerInfor?.ID,
        // customer_phone: this.customerInfor.phone,
        stock_out_details: this.selectedLot.map((x) => ({
          product_variant_id: x.product_variant_id,
          product_lot_id: x.product_lot_id,
          variant_qty: x.qty,
        })),
      };
      this.isLoading = true;

      const res = await store
        .dispatch("stockOut/createStockOut", params)
        .then(async (response) => {
          this.$emit("refresh");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Tạo phiếu xuất thành công"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return null;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async onClickLot(indexOrderItem, indexLot, lot, item) {
      if (this.selectedLot.length === 0) {
        this.selectedLot.push({
          product_variant_id: item?.data?.product_variant_id,
          product_lot_id: lot.product_lot_id,
          qty: item?.data.qty,
        });
        this.orderProductItems[indexOrderItem].lot[indexLot].active =
          !lot.active;
      } else if (this.selectedLot.length >= 1) {
        const selectedLotInfo = this.selectedLot?.find((lotItem) => {
          return lotItem.product_variant_id === item?.data?.product_variant_id;
        });

        if (selectedLotInfo?.product_variant_id) {
          if (selectedLotInfo.product_lot_id === lot.product_lot_id) {
            this.selectedLot = this.selectedLot.filter(
              (lotItem) =>
                lotItem.product_lot_id !== selectedLotInfo.product_lot_id
            );
            this.orderProductItems[indexOrderItem].lot[indexLot].active =
              !lot.active;
          } else {
            selectedLotInfo.product_lot_id = lot.product_lot_id;

            this.orderProductItems[indexOrderItem].lot = this.orderProductItems[
              indexOrderItem
            ].lot.map((x) => ({
              ...x,
              active: false,
            }));

            this.orderProductItems[indexOrderItem].lot[indexLot].active =
              !lot.active;
          }
        } else {
          this.selectedLot.push({
            product_variant_id: item?.data?.product_variant_id,
            product_lot_id: lot.product_lot_id,
            qty: item?.data?.qty,
          });

          this.orderProductItems[indexOrderItem].lot[indexLot].active =
            !lot.active;
        }
      }
    },
    async handleChangeInventory(data) {
      this.inventoryInfo = data;
      await this.viewWareHouseProductLot();
      this.orderProductItems.forEach((item, orderItemIndex) => {
        this.onClickLot(orderItemIndex, 0, item.lot[0], item);
      });
    },
    async viewWareHouseProductLot() {
      const params = {
        product_variant_ids: this.orderProductItems?.map(
          (x) => x.data.product_variant_id
        ),
        inventory_id: this.inventoryInfo.id,
      };
      this.isLoading = true;

      const res = await store
        .dispatch("ecomInventory/fetchLotWarehouseProduct", params)
        .then(async (response) => {
          const data = response.data.data;
          this.orderProductItems = this.orderProductItems?.map((x) => {
            const founded = data.find((i) => {
              return i.product_variant_id == x.data.product_variant_id;
            });
            if (founded) {
              const convertLots = founded.data?.map((x) => ({
                ...x,
                active: false,
              }));
              return {
                ...x,
                lot: convertLots,
              };
            } else return { ...x, lot: [] };
          });
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return null;
        })
        .finally(() => {
          this.isLoading = false;
        });
      return res;
    },
    onSubmit(event) {
      event.preventDefault();
    },
    onReset(event) {
      event.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    getAddress(data) {
      let address = "";
      if (data.address2) {
        address = data.address2;
      }
      if (data.address1) {
        address = address + ", " + data.address1;
      }

      return address;
    },
  },
};
</script>
  
  <style>
</style>
  
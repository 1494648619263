<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group :label="$t('lbl_fullname')">
        <b-form-input
          v-model="data.name"
          :disabled="!isEdit"
          type="text"
          placeholder="Enter Fullname"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group :label="$t('lbl_phone_number')">
        <b-form-input
          v-model="data.phone"
          :disabled="!isEdit"
          type="text"
          placeholder="Enter Phone number"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group :label="$t('lbl_province')">
        <b-form-input
          v-model="data.address1"
          :disabled="!isEdit"
          type="text"
          placeholder="Enter Province"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group :label="$t('lbl_address')">
        <b-form-input
          v-model="data.address2"
          :disabled="!isEdit"
          type="text"
          placeholder="Enter Address"
          required
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormSelect,
  BButton
} from "bootstrap-vue";

export default {
  name: "ReceiverInfo",
  props: {
    data: {
      type: Object,
      required: true
    },
    isEdit: Boolean
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BButton
  }
};
</script>

<style>
</style>

<template>
  <b-overlay
    :show="isLoading"
    variant="light"
    :opacity="0.75"
    blur="2px"
    centered
    style="min-height: 10rem"
  >
    <div class="border-bottom p-1">
      <b-row>
        <!-- <b-col :md="currentStatus === 100 ? '9' : '12'">
          <ul class="list-unstyled multi-steps">
            <li
              v-for="(step, index) in steps"
              :key="index"
              class="step"
              :class="
                currentStatus !== 0 &&
                step.order_number === currentStatus &&
                step.order_number !== (step.max_steps || steps.length)
                  ? 'is-active'
                  : step.order_number > currentStatus || currentStatus === 100
                  ? 'after-step'
                  : 'before-step'
              "
            >
              <div class="step-icon">
                <feather-icon
                  v-if="
                    (step.order_number <= currentStatus &&
                      currentStatus !== 100) ||
                    (currentStatus === 100 &&
                      (step.date || step.order_number == 1))
                  "
                  icon="CheckIcon"
                  size="20"
                  class="mr-0"
                  style="top: 6px; left: 36%"
                />
                <span
                  v-if="
                    step.order_number > currentStatus ||
                    (currentStatus === 100 &&
                      !step.date &&
                      step.order_number !== 1)
                  "
                  class
                  style="font-weight: 700"
                  >{{ step.order_number }}</span
                >
              </div>
              <div style="margin-top: 40px">
                <span
                  class="d-block"
                  v-if="
                    (step.order_number <= currentStatus &&
                      currentStatus !== 100) ||
                    (currentStatus === 100 &&
                      (step.date || step.order_number == 1))
                  "
                >
                  {{ step.title_done }}
                </span>
                <span class="d-block" v-else>
                  {{ step.title }}
                </span>
                <small class="d-block text-secondary" v-if="step.date">{{
                  appUtils.formatDateTime(step.date)
                }}</small>
                <span class="font-weight-bolder" style="font-size: 14px">{{
                  step.updated_name
                }}</span>
               
              </div>
            </li>
          </ul>
        </b-col>-->
        <b-col :md="currentStatus === 100 ? '9' : '12'">
          <el-steps
            :direction="
              appUtils.checkIsMobileAndTablet() ? 'vertical' : 'horizontal'
            "
            :space="appUtils.checkIsMobileAndTablet() ? 80 : 400"
            align-center
          >
            <el-step
              v-for="(step, index) in steps"
              :key="index"
              :title="step && step.title"
              :status="
                currentStatus !== 0 && step.order_number <= currentStatus
                  ? 'success'
                  : 'process'
              "
            >
              <template slot="title">
                <div
                  class="d-flex flex-md-column justify-content-between justify-content-md-start align-items-start align-items-md-center"
                >
                  <div>
                    <span
                      class="d-block"
                      v-if="
                        (step.order_number <= currentStatus &&
                          currentStatus !== 100) ||
                        (currentStatus === 100 &&
                          (step.date || step.order_number == 1))
                      "
                    >{{ step.title_done }}</span>
                    <span class="d-block" v-else>{{ step.title }}</span>
                  </div>

                  <b-button
                    variant="primary"
                    size="sm"
                    @click="changeStatus(step)"
                    v-if="step.order_number === currentStatus + 1"
                  >
                    {{
                    $t(currentStatus === 2 ? "Tạo phiếu" : "Xác nhận")
                    }}
                  </b-button>
                </div>
              </template>
              <template slot="description">
                <div>
                  <small class="d-block text-secondary" v-if="step.date">
                    {{
                    appUtils.formatDateTime(step.date)
                    }}
                  </small>
                  <span
                    class="font-weight-bolder text-black"
                    style="font-size: 12px"
                  >{{ step.updated_name }}</span>
                </div>
                <small
                  class="view-image"
                  v-if="detailDelivery.id && step.order_number === 4 && currentStatus >= step.order_number"
                  @click="$emit('viewConfirmImage')"
                >Xem hình ảnh giao hàng</small>
              </template>
            </el-step>
          </el-steps>
        </b-col>

        <b-col v-if="currentStatus === 100" md="3">
          <div class="badge-cancel">Đã huỷ</div>
          <div v-if="this.historyCanceled" style="margin-top: 4px">
            <small
              class="d-block text-secondary"
              style="font-size: 13px"
              v-if="historyCanceled.time_changed"
            >
              <b>{{ appUtils.formatDateTime(historyCanceled.time_changed) }}</b>
              bởi
              <b>{{ historyCanceled.user_infor.name }}</b>
            </small>
            <div class="flex items-center gap-1" v-if="historyCanceled.reject_reason">
              <feather-icon icon="InfoIcon" size="14"></feather-icon>
              <small class="d-block">Lý do huỷ: {{ historyCanceled.reject_reason }}</small>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import appUtils from '@/utils/appUtils'
import { PRODUCT_ORDER_STATUS, PAYMENT_TYPE } from '@/utils/constant'
import { Step, Steps } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

export default {
  name: 'StepLine',
  components: {
    'el-steps': Steps,
    'el-step': Step
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    currentStatus: {
      type: Number,
      default: 0
    },
    orderId: {
      type: Number,
      default: 0
    },
    detailDelivery: {
      type: Object,
      default: () => {}
    },
    historyCanceled: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      appUtils,
      PRODUCT_ORDER_STATUS,
      PAYMENT_TYPE,
      isLoading: false
    }
  },
  methods: {
    async changeStatus(data) {
      if (data.order_number === 3) {
        this.$emit('openModalExport')
      } else if (data.order_number === 4) {
        this.$emit('openModalUploadFile')
      } else {
        const params = {
          id: this.orderId,
          order_number: data.order_number,
          max_steps: data.max_steps
        }
        this.isLoading = true
        const res = await store
          .dispatch('ecomOrder/changeStatusOrder', params)
          .then(response => {
            this.$emit('refresh')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('lbl_update_order_status_success'),
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('lbl_error_fetching_data'),
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            return null
          })
          .finally(() => {
            this.isLoading = false
          })
        return res
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step::before {
  content: '' !important;
  width: 0;
  height: 0;
  border: none;
  margin-bottom: 0;
}

.step-icon {
  width: 34px;
  height: 34px;
  background-color: #fff;
  border: 2px solid #476dd6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  position: absolute;
  left: calc((100% - 36px) / 2);
}

.after-step {
  color: #808080;
  &::after {
    background-color: #808080;
  }
  .step-icon {
    border: 2px solid #808080;
  }
}
.step-active {
  color: #476dd6;
  &::after {
    background-color: #476dd6;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}

.badge-cancel {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  background: #ea5455;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.view-image {
  color: #000;
  text-decoration: underline;
  &:hover {
    color: #20419b;
    cursor: pointer;
  }
}
</style>

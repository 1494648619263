<template>
  <div>
    <b-row class="align-items-center">
      <b-col cols="12" md="2" xxl="1">
        <div class="bg-light text-center py-50 rounded-lg">
          <img
            class="border rounded-lg"
            :src="product.primary_image"
            :alt="product.name"
            :height="80"
          />
        </div>
      </b-col>
      <b-col cols="12" md="10" xxl="11">
        <h2 class="h5 font-weight-bolder ml-3">
          {{ product.product_name }}
        </h2>
        <b-row class="align-items-center ml-2">
          <b-col cols="12" md="2">
            <b-badge variant="light">
              <span class="h4 d-block text-dark p-50 mb-0"
                >x {{ product.qty }}</span
              >
            </b-badge>
          </b-col>
          <b-col cols="12" md="4">
            <span class="h4 text-primary mb-0">
              {{ appUtils.numberFormat(product.price, "VNĐ") }}
              <span class="text-secondary"
                >/ {{ product.product_variant_unit_name }}</span
              >
            </span>
          </b-col>
          <b-col cols="12" md="2">
            <b-badge
              v-if="product.voucher_info && product.voucher_info.code"
              class="coupon"
              variant="warning"
            >
              <span v-if="product.voucher_info.discount_percentage"
                >-{{ product.voucher_info.discount_percentage }}%</span
              >
              <span v-else>
                -{{
                  appUtils.numberFormat(
                    product.voucher_info.discount_amount,
                    "VND"
                  )
                }}
              </span>
            </b-badge>
          </b-col>
          <!-- <b-col
            cols="12"
            md="3"
            class="text-right ml-auto"
            v-if="product.status !== SUPPLIER_ORDERS_STATUS.CANCELED"
          >
            <v-select
              class="bg-white"
              v-model="selectedOption"
              :clearable="false"
              label="title"
              :options="options"
              @input="(value) => handleChangeSelected(value, product)"
            />
          </b-col> -->
          <!-- <b-col
            cols="12"
            md="3"
            class="text-right ml-auto"
            v-if="product.status !== SUPPLIER_ORDERS_STATUS.CANCELED"
          >
            <b-button
              variant="danger"
              block
              @click="rejectOrder"
            >{{ $t('lbl_reject_supplier_order') }}</b-button>
          </b-col>
          <b-col cols="12" md="3" class="text-right ml-auto" v-else>
            <div
              class="cancel-order d-flex align-items-center justify-content-center"
            >{{ $t('lbl_reject_supplier_order_status') }}</div>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
    <!-- 
    <b-row class="pt-2">
      <b-col>
        <Definition :ordersId="ordersId" :dataProp="product.order_item_attachments" />
      </b-col>
      <b-col>
        <b-button
          @click="handleCreateRequisitionForm(product)"
          variant="primary"
        >{{ $t('lbl_create_requisition_form') }}</b-button>
      </b-col>
    </b-row> -->

    <b-modal
      v-if="product && product.id"
      :id="`customer-files-modal-${product.id}`"
      size="lg"
      scrollable
      hide-header
      :ok-title="$t('lbl_update')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalCustomerFiles"
      :ok-disabled="checkOrderFormFile(product)"
      @ok="handleSubmitStatus(product.order_item_form_attachments)"
      @hide="handleRefreshData"
    >
      <CustomerFiles :dataProp="product.order_item_form_attachments" />
    </b-modal>

    <b-modal
      v-if="product && product.id"
      :id="`modal-requisition-form-${product.id}`"
      size="xl"
      scrollable
      hide-header
      :ok-title="$t('lbl_create')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalRequisitionForm"
      @ok="handleTriggerRequisitionForm"
      @hide="closeModalRequisitionForm"
    >
      <CreateRequisitionForm
        @onCreate="handleSubmitRequisitionForm"
        :createRequisitionFormCount="createRequisitionFormCount"
        :productData="product"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import vSelect from "vue-select";
import appUtils from "@/utils/appUtils";
import {
  ORDERS_STATUS,
  ORDER_DELIVERY_STATES,
  SUPPLIER_ORDERS_STATUS,
} from "@/utils/constant";
import { createPopper } from "@popperjs/core";
import { ref } from "@vue/composition-api";
import useOrdersList from "../../useOrdersList";
import Definition from "./Definition.vue";
import CustomerFiles from "./CustomerFiles.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CreateRequisitionForm from "../../../PrintOrderForm/CreateRequisitionForm.vue";

export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
    ordersId: Number,
  },
  components: {
    vSelect,
    Definition,
    BModal,
    CustomerFiles,
    CreateRequisitionForm,
  },
  data() {
    return {
      appUtils,
      options: [],
      selected: {},
      selectedOption: null,
      placement: "bottom",
      showModalCustomerFiles: false,
      SUPPLIER_ORDERS_STATUS,
      showModalRequisitionForm: false,
      createRequisitionFormCount: 0,
    };
  },
  beforeMount() {
    let data = [];
    Object.entries(ORDERS_STATUS).map(([key, value]) => {
      if (
        value === ORDERS_STATUS.WAITING_CONFIRM ||
        value === ORDERS_STATUS.ERROR_PAYMENT
      ) {
        return;
      }

      data.push({
        label: this.$t(key),
        value,
      });
    });
    this.options = [...data];
    this.options = [...ORDER_DELIVERY_STATES];
    this.selectedOption =
      ORDER_DELIVERY_STATES.find(
        (x) => x.order_number === this.orderData.status
      ) || null;
  },
  watch: {
    product() {
      this.selectedOption = this.product.status;
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
    handleSelecting(option) {
      if (option.value === ORDERS_STATUS.CANCELED) {
        const nodeEl = this.$createElement;
        const messageVNode = nodeEl("div", {
          domProps: {
            innerHTML: `Are you sure want to update <strong>${option.label}</strong>`,
          },
        });

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: "Please confirm and enter reason",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.updateStatusOrder(this.ordersId, {
                id: record.data.id,
                order_number: option.order_number,
                max_steps: option.max_steps,
              });
              // Code here...
              console.log(value);
            }
          })
          .catch((err) => {
            // An error occurred
            console.log(err);
          });
        return;
      }
    },
    handleChangeSelected(option, record) {
      if (!record?.data?.id) return;
      console.log(record);
      console.log(record.deliverable_status);

      const lastIndexOrderItem = record.deliverable_status.length;
      const foundUrlEmpty = record.order_item_form_attachments.find(
        (x) => x.url === ""
      );
      console.log(foundUrlEmpty);
      if (
        record.deliverable_status.length &&
        option.order_number ===
          record.deliverable_status[lastIndexOrderItem - 1].order_number &&
        foundUrlEmpty
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_this_test_order_requires_documents"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
      this.updateStatusOrder(this.ordersId, {
        id: record.data.id,
        order_number: option.order_number,
        max_steps: option.max_steps,
      });
    },
    rejectOrder() {
      const nodeEl = this.$createElement;
      const messageVNode = nodeEl("div", {
        domProps: {
          innerHTML: this.$t("lbl_reject_supplier_order_content"),
        },
      });

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t("lbl_please_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("lbl_yes"),
          cancelTitle: this.$t("lbl_no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.updateStatusOrder(this.ordersId, {
              id: this.product.id,
              order_number: SUPPLIER_ORDERS_STATUS.CANCELED,
              max_steps: this.options[0].max_steps,
            });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
      return;
    },
    handleShowModalCustomerFiles() {
      this.showModalCustomerFiles = true;
    },
    async handleSubmitStatus(data) {
      if (!data?.length) {
        this.showModalCustomerFiles = false;
        return;
      }

      try {
        await Promise.all(
          data.map(async (item) => {
            if (!item?.status?.id) return;
            // if (item.status.id === 3 && !item.description) {
            //   this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: this.$t("lbl_description_field_cannot_be_empty"),
            //       icon: "AlertTriangleIcon",
            //       variant: "danger"
            //     }
            //   });
            //   return;
            // }
            const params = {
              status: item.status.id,
            };

            if (item.status.id === 3) {
              params.reason = item.reason;
            }

            await this.$rf
              .getRequest("CommonRequest")
              .putOrderFormItem(item.id, params);
          })
        );
        this.showModalCustomerFiles = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_update_status_success"),
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.viewDetailOrders({ id: this.ordersId });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_update_status_fail"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    handleRefreshData() {
      this.viewDetailOrders({ id: this.ordersId });
      this.showModalCustomerFiles = false;
    },
    checkOrderFormFile(product) {
      return product?.order_item_form_attachments?.every((item) => !item?.url);
    },
    handleCreateRequisitionForm(product) {
      const orderItemId = product?.data?.id;
      if (!orderItemId) return;
      this.showModalRequisitionForm = true;
    },
    closeModalRequisitionForm() {
      this.showModalRequisitionForm = false;
      // this.createRequisitionFormCount = 0;
    },
    handleTriggerRequisitionForm() {
      this.createRequisitionFormCount++;
    },
    handleSubmitRequisitionForm(formData) {
      if (!this.product?.data?.id) return;

      if (this.createRequisitionFormCount) {
        const data = {
          ...formData,
          orderId: this.product.id,
        };
        this.$store.commit("orders/updateRequisitionForm", data);
        this.createRequisitionFormCount = 0;
        this.$router.replace({
          name: "print-order-form",
          params: {
            id: this.product.id,
          },
        });
      }
    },
  },
  setup() {
    const dataTag = ref({});
    const { updateStatusOrder, viewDetailOrders } = useOrdersList();
    return {
      dataTag,
      updateStatusOrder,
      viewDetailOrders,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5) !important;
  transform: scale(0.7) !important;
}
.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(0.7) !important;
}
.vs__deselect svg {
  fill: currentColor !important;
}

.attachment-product {
  background-color: #dee4f8;
  color: #20419b;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cancel-order {
  background-color: #f8dede;
  color: #ff0000;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>

<template>
  <b-overlay
    :show="isLoading"
    variant="light"
    :opacity="0.75"
    blur="2px"
    centered
    style="min-height: 10rem"
  >
    <div>
      <b-form>
        <b-form-group label="Chọn ảnh:" v-if="status !== DELIVERY_CONFIRM_STATUS.CONFIRMED">
          <b-form-file
            v-model="form.image"
            autofocus
            :placeholder="$t('lbl_select_a_file_or_drag_and_drop_a_file_here')"
            :drop-placeholder="$t('lbl_drag_and_drop_the_file_here_')"
            :browse-text="$t('lbl_browse_text')"
            @change="handleChangeFile"
            accept=".jpg, .png"
          ></b-form-file>
          <img id="preview" :src="preview" alt class="preview-image" :class="preview ? 'mt-1' : ''" />
        </b-form-group>
        <b-form-group
          :label="$t('lbl_notes')"
          v-if="!(status === DELIVERY_CONFIRM_STATUS.CONFIRMED && !form.note)"
        >
          <b-form-input
            v-model="form.note"
            :disabled="status === DELIVERY_CONFIRM_STATUS.CONFIRMED"
            :placeholder="$t('lbl_enter_notes')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Ảnh đã tải lên" v-if="confirmImg">
          <img :src="confirmImg" alt class="preview-image" />
        </b-form-group>
      </b-form>
      <div
        class="flex items-center justify-content-end w-100 gap-3 pt-2 pb-1"
        v-if="status !== DELIVERY_CONFIRM_STATUS.CONFIRMED"
      >
        <b-button variant="outline-primary" @click="handleSave">Lưu</b-button>
        <b-button variant="primary" @click="handleConfirm">Lưu và hoàn thành</b-button>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { DELIVERY_CONFIRM_STATUS } from '@/utils/constant'

export default {
  name: 'ModalUploadFileConfirm',
  props: {
    orderId: Number,
    dataDelivery: Object
  },
  data() {
    return {
      form: {
        image: null,
        note: ''
      },
      isLoading: false,
      confirmImg: '',
      status: 1,
      preview: null,
      DELIVERY_CONFIRM_STATUS
    }
  },
  mounted() {
    this.handleOpen()
  },
  watch: {
    'dataDelivery.delivery_attach_file_url'(value) {
      this.confirmImg = value
    },
    'dataDelivery.delivery_note'(value) {
      this.form.note = value
    },
    'dataDelivery.delivery_status'(value) {
      this.status = value
    }
  },
  methods: {
    handleChangeFile(file) {
      // show preview img
      let preview = document.getElementById('preview')
      preview.src = URL.createObjectURL(file.target.files[0])
      this.preview = preview.src
      preview.onload = function() {
        URL.revokeObjectURL(preview.src) // free memory
      }
    },
    async handleSave() {
      try {
        this.isLoading = true
        if (!this.form.image && !this.confirmImg) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Bạn cần tải lên ảnh',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        } else {
          if (this.form.image) {
            await this.uploadFile(this.form.image).then(async url => {
              let params = {
                attachment_url: url,
                deliver_notes: this.form.note,
                order_id: this.orderId
              }
              await this.$rf
                .getRequest('CommonRequest')
                .saveConfirmImage(params)
                .then(async res => {
                  if (res.status === 200) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Lưu thành công',
                        icon: 'CheckCircleIcon',
                        variant: 'success'
                      }
                    })
                    this.form.image = null
                    this.preview = null
                    this.$emit('save')
                    this.isLoading = false
                  }
                })
            })
          } else {
            await this.$rf
              .getRequest('CommonRequest')
              .saveConfirmImage({
                attachment_url: this.confirmImg,
                deliver_notes: this.form.note,
                order_id: this.orderId
              })
              .then(async res => {
                if (res.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Lưu thành công',
                      icon: 'CheckCircleIcon',
                      variant: 'success'
                    }
                  })
                  this.form.image = null
                  this.preview = null
                  this.$emit('save')
                  this.isLoading = false
                }
              })
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async handleConfirm() {
      await this.handleSave()
      await this.$rf
        .getRequest('CommonRequest')
        .confirmDelivery(this.orderId)
        .then(() => {
          this.$emit('refresh')
        })
      this.$emit('save')
    },
    // upload file
    async uploadFile(file) {
      const params = {
        file,
        FolderTarget: `certificates`,
        IdTarget: this.orderId
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('CommonRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    handleOpen() {
      this.confirmImg = this.dataDelivery?.delivery_attach_file_url
      this.status = this.dataDelivery?.delivery_status
      this.form.note = this.dataDelivery?.delivery_note
    }
  }
}
</script>
<style lang="scss" scoped>
.preview-image {
  width: 120px;
  margin: 0 auto;
  object-fit: cover;
  display: flex;
  justify-content: center;
  @media screen and(min-width: 768px) {
    width: 240px;
    max-height: 340px;
  }
}
</style>
<template>
  <b-overlay :show="isLoading" variant="light" :opacity="0.75" blur="2px">
    <div v-if="data">
      <div
        class="font-weight-bolder h3 mb-1"
        style="padding: 4px 0 4px 4px; border-left-width: 4px;"
      >{{ $t('lbl_customer_files') }}</div>
      <div style="padding-bottom:120px">
        <div v-for="(item,index) in data" :key="item.id">
          <b-row>
            <b-col cols="12" class="font-weight-bold fs-20 mb-1">{{`${index+1}. ${item.title}`}}</b-col>
            <b-row class="d-flex align-items-center px-2" style="width:100%">
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center px-2 cs-label">
                      <div
                        @click="handleClickFile(item.url)"
                        style="flex:1"
                        class="fs-18 cursor-pointer"
                        :class="item&&item.url?'cs-text':''"
                      >{{ item.url?getFileName(item.url):$t('lbl_missing_required_documents') }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="item && item.url" cols="6">
                <v-select
                  id="order-form-status"
                  v-model="item.status"
                  label="name"
                  value="id"
                  :clearable="false"
                  :options="options"
                  :calculate-position="withPopper"
                />
              </b-col>
              <b-col cols="12">
                <b-form-input
                  class="mt-1 required"
                  v-if="item.status && item.status.id===options[2].id"
                  type="text"
                  v-model="item.reason"
                  :placeholder="$t('lbl_enter_description')"
                />
              </b-col>
            </b-row>
          </b-row>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

  <script>
import useOrdersList from "../../useOrdersList";
import appUtils from "../../../../utils/appUtils";
import { ORDER_FORM_STATUS_OPTIONS } from "../../../../utils/constant";
import vSelect from "vue-select";

export default {
  name: "CustomerFiles",
  props: {
    dataProp: Array,
    ordersId: Number
  },
  components: { vSelect },
  setup() {
    const { uploadFileDefinition } = useOrdersList();
    return {
      uploadFileDefinition
    };
  },
  data() {
    return {
      data: [],
      isLoading: false,
      ORDER_FORM_STATUS_OPTIONS,
      appUtils
    };
  },
  mounted() {
    this.handleMapData();
  },

  watch: {
    dataProp() {
      this.handleMapData();
    }
  },
  computed: {
    options() {
      return [
        { id: ORDER_FORM_STATUS_OPTIONS.PENDING, name: this.$t("lbl_pending") },
        { id: ORDER_FORM_STATUS_OPTIONS.ACCEPT, name: this.$t("lbl_accept") },
        { id: ORDER_FORM_STATUS_OPTIONS.REJECT, name: this.$t("lbl_reject") }
      ];
    }
  },
  methods: {
    handleMapData() {
      if (this.dataProp) {
        this.data =
          this.dataProp?.map(item => {
            if (typeof item.status !== "number") return item;
            const statusLabel = this.options.find(
              option => Number(option.id) === Number(item.status)
            )?.name;
            item.status = { id: item.status, name: statusLabel };
            return item;
          }) || [];
      }
    },

    checkImage(path) {
      const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

      const extension = path.substring(path.lastIndexOf(".")).toLowerCase();
      if (imageExtensions.includes(extension)) {
        return true;
      }

      return false;
    },
    handleClickFile(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
    getFileName(string) {
      if (typeof string === "string" && string.includes("https")) {
        const fileName = string.substring(string.lastIndexOf("/") + 1);
        return appUtils.truncateHash(fileName, 20);
      } else {
        return appUtils.truncateHash(string?.name, 20);
      }
    },
    selectedStatus(status) {
      return this.options.find(item => item.id === status);
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1]
            }
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            }
          }
        ]
      });

      return () => popper.destroy();
    }
  }
};
</script>

  <style lang="scss" scoped>
.upload-btn {
  width: 150px;
  border: 1px dashed #20419b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 9px;
  cursor: pointer;
}
.cs-text {
  text-decoration: underline;
  font-size: 16px;
  text-align: center;
}

.cs-label {
  height: 40px;
  background-color: #dee4f8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: ;?
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticStyle:{"min-height":"10rem"},attrs:{"show":_vm.isLoading,"variant":"light","opacity":0.75,"blur":"2px","centered":""}},[_c('div',{staticClass:"border-bottom p-1"},[_c('b-row',[_c('b-col',{attrs:{"md":_vm.currentStatus === 100 ? '9' : '12'}},[_c('el-steps',{attrs:{"direction":_vm.appUtils.checkIsMobileAndTablet() ? 'vertical' : 'horizontal',"space":_vm.appUtils.checkIsMobileAndTablet() ? 80 : 400,"align-center":""}},_vm._l((_vm.steps),function(step,index){return _c('el-step',{key:index,attrs:{"title":step && step.title,"status":_vm.currentStatus !== 0 && step.order_number <= _vm.currentStatus
                ? 'success'
                : 'process'}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex flex-md-column justify-content-between justify-content-md-start align-items-start align-items-md-center"},[_c('div',[(
                      (step.order_number <= _vm.currentStatus &&
                        _vm.currentStatus !== 100) ||
                      (_vm.currentStatus === 100 &&
                        (step.date || step.order_number == 1))
                    )?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(step.title_done))]):_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(step.title))])]),(step.order_number === _vm.currentStatus + 1)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStatus(step)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.currentStatus === 2 ? "Tạo phiếu" : "Xác nhận"))+" ")]):_vm._e()],1)]),_c('template',{slot:"description"},[_c('div',[(step.date)?_c('small',{staticClass:"d-block text-secondary"},[_vm._v(" "+_vm._s(_vm.appUtils.formatDateTime(step.date))+" ")]):_vm._e(),_c('span',{staticClass:"font-weight-bolder text-black",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(step.updated_name))])]),(_vm.detailDelivery.id && step.order_number === 4 && _vm.currentStatus >= step.order_number)?_c('small',{staticClass:"view-image",on:{"click":function($event){return _vm.$emit('viewConfirmImage')}}},[_vm._v("Xem hình ảnh giao hàng")]):_vm._e()])],2)}),1)],1),(_vm.currentStatus === 100)?_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"badge-cancel"},[_vm._v("Đã huỷ")]),(this.historyCanceled)?_c('div',{staticStyle:{"margin-top":"4px"}},[(_vm.historyCanceled.time_changed)?_c('small',{staticClass:"d-block text-secondary",staticStyle:{"font-size":"13px"}},[_c('b',[_vm._v(_vm._s(_vm.appUtils.formatDateTime(_vm.historyCanceled.time_changed)))]),_vm._v(" bởi "),_c('b',[_vm._v(_vm._s(_vm.historyCanceled.user_infor.name))])]):_vm._e(),(_vm.historyCanceled.reject_reason)?_c('div',{staticClass:"flex items-center gap-1"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"14"}}),_c('small',{staticClass:"d-block"},[_vm._v("Lý do huỷ: "+_vm._s(_vm.historyCanceled.reject_reason))])],1):_vm._e()]):_vm._e()]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <span class="title-form">{{ $t("lbl_create_requisition_form") }}</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <div v-for="section in list_section" :key="section.section_title">
          <span class="cs-title">{{ $t(section.section_title) }}</span>
          <template v-if="!section.row">
            <template v-if="!section.layout">
              <div
                v-for="input in section.input_list"
                :key="input.key"
                class="cs-flex flex-wrap"
              >
                <span v-if="input.label" class="cs-label">{{
                  $t(input.label)
                }}</span>
                <component
                  :is="input.component"
                  class="cs-content"
                  v-model="form[input.key]"
                  :options="input.options || optionsEditor"
                  :name="input.name"
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  :style="input.style"
                />
                <span
                  v-if="input.note"
                  class="cs-flex"
                  style="flex-basis: 100%"
                  >{{ $t(input.note) }}</span
                >
              </div>
            </template>
            <template v-else>
              <div
                v-for="input in section.input_list"
                :key="input.key"
                style="padding: 6px"
                :style="input.style"
              >
                <p v-if="input.label" class="items-center" style="margin-bottom: 0.5rem;" :style="input.labelStyle">{{ $t(input.label) }}</p>
                <component
                  :is="input.component"
                  class="cs-content"
                  v-model="form[input.key]"
                  :options="input.options || optionsEditor"
                  :name="input.name"
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  :style="input.inputStyle"
                />
              </div>
            </template>
          </template>
          <template v-else>
            <b-row class="mt-1 mb-1 mx-0">
              <b-col
                v-for="input in section.input_list"
                :key="input.key"
                class="text-center"
                :cols="input.colSpan"
              >
                <strong>{{ $t(input.label) }}</strong>
              </b-col>
            </b-row>
            <b-row
              v-for="(testInfo, index) in form.test_selection"
              :key="index"
              class="mt-1 px-1"
            >
              <b-col
                v-for="input in section.input_list"
                :key="input.key"
                :class="input.component != 'b-form-checkbox' && 'text-center'"
                :cols="input.colSpan"
              >
                <component
                  v-if="input.component != 'b-form-checkbox'"
                  :is="input.component"
                  class="cs-content"
                  v-model="testInfo[input.key]"
                  :options="input.options || optionsEditor"
                  :name="input.name"
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                />
                <template v-else>
                  <div class="cs-flex cs-checkbox py-0">
                    <input
                      v-model="testInfo[input.key]"
                      type="checkbox"
                      class="cs-none"
                    />
                    <label class="cs-content">
                      <strong>{{ testInfo[input.key] }}</strong>
                    </label>
                  </div>
                </template>
              </b-col>
            </b-row>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  full_name: "",
  identity_card_number: "",
  medical_record_no: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  physician_name: "",
  contact_number: null,
  email: "",
  nsr_no: null,
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: ""
};

const optionsEditor = {
  placeholder: "",
  theme: "snow"
};

export default {
  name: "CreateRequisitionForm",
  props: {
    productData: Object,
    createRequisitionFormCount: Number,
    formCount: Number,
    formData: Object,
    list_section: Array
  },
  components: { quillEditor },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      optionsEditor
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    createRequisitionFormCount() {
      this.handleCreateRequisitionForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    }
    // formData: {
    //   deep: true,
    //   handler(data) {
    //     this.handleMapData({}, data);
    //   }
    // }
  },
  created() {
    this.list_section.forEach((section) => {
      section.input_list.forEach((input) => {
        this.$set(this.form, input.key, null);
        if (
          ["b-form-radio-group", "b-form-checkbox-group"].includes(
            input.component
          )
        ) {
          input.options.forEach((opt) => {
            opt.text = opt.labelMultilang
              ? this.$t(opt.labelMultilang)
              : opt.text;
          });
        } else if (input.component === "quillEditor") {
          input.options = this.optionsEditor;
        }
      });
    });
  },
  mounted() {
    if (this.orders?.viewDetail) {
      this.handleMapData(this.orders.viewDetail, this.formData);
    }
  },
  methods: {
    handleChangeSolidTumourType(isChecked, index) {
      if (isChecked) {
        this.form.solid_tumour_type.push(index);
      } else {
        this.form.solid_tumour_type = this.form.solid_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleChangeHaematologicalTumourType(isChecked, index) {
      if (isChecked) {
        this.form.haematological_tumour_type.push(index);
      } else {
        this.form.haematological_tumour_type = this.form.haematological_tumour_type.filter(
          item => item !== index
        );
      }
    },
    handleCreateRequisitionForm() {
      if (this.createRequisitionFormCount) {
        this.$emit("onCreate", this.form);
      } else {
        this.form = { ...DEFAULT_FORM };
      }
    },
    handleMapData(data, formData) {
      if (formData) {
        this.form = { ...this.form, ...formData };
      }
      const patientInfo =
        data?.order_contact?.find(item => item?.type === 2) ||
        data?.order_contact?.find(item => item?.type === 1);
      this.form.full_name = formData?.full_name || patientInfo?.name;
      this.form.paid_by = formData?.paid_by || 2;
      this.form.bill_address =
        formData?.bill_address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.bill_phone = formData?.bill_phone || patientInfo?.phone;
      this.form.bill_email = formData?.bill_email || patientInfo?.email;
      this.form.patient_name = formData?.patient_name || patientInfo?.name;
      if (!this.productData) return;

      const listProduct = formData?.test_selection || [this.productData];
      this.form.test_selection = [...listProduct];
      this.form.sample_requirement = [...listProduct];
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}

.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
</style>
